<template>
  <div class="buttons-container">
    <a class="button" v-for="link in links" v-bind:key="link.url" :href="link.url" target="_blank">
      <span v-if="link.url" v-html="link.label" />
      <router-link v-if="link.localUrl" :to="link.localUrl" v-html="link.label" />
    </a>
  </div>
</template>

<script>

const backupList = [
  {
    label: `Stream our newest single, Seeing Illusions!`,
    url: 'https://open.spotify.com/album/4t4ZdgzAU4P1pCQahhpjac'
  },
  {
    label: `Buzz Music LA - All Along`,
    url: 'https://www.buzz-music.com/post/feel-the-love-with-super-ok-s-new-single-all-along'
  },
  {
    label: `Stream our single, All Along!`,
    url: 'https://open.spotify.com/album/4t4ZdgzAU4P1pCQahhpjac'
  },
  {
    label: `Stream our single, Changes!`,
    url: 'https://open.spotify.com/album/4GX9i0ONBPzwO2Mfkeg2fo'
  },
  {
    label: `Stream our single, Annoyed the Void!`,
    url: 'https://open.spotify.com/track/1AvFwHote8iJAvm596czhI?si=b27b762b560e4285'
  },
  {
    label: 'Watch the "Push" Official Music Video',
    url: 'https://youtu.be/tGf-F-sF7BQ?si=MVKpmm4viQ5zGRUE'
  },
  /*
  {
    label: 'Departures first anniversary retrospective with Chris Harvey',
    url: 'https://youtu.be/XnIXMJMxFcU'
  },
  */
  {
    label: 'Spotify',
    url: 'https://open.spotify.com/album/75ijGKYMwGG5CZne26bz9q?si=4SEd8iK6TzeXaEYhR_FBvg'
  },
  {
    label: 'Follow us on Instagram',
    url: 'https://www.instagram.com/wearesuperok/'
  },
  {
    label: 'YouTube',
    url: 'https://www.youtube.com/channel/UCDic5bNX3nL6SVoaNvaowQA'
  },
  /*
  {
    label: 'Divide and Conquer - Departures Review',
    url: 'https://www.divideandconquermusic.com/indie-music-album-reviews/super-ok-departures'
  },
  {
    label: 'DOPECAUSEWESAID - Departures Review',
    url: 'https://dopecausewesaid.com/dope-features/review-departures-album-by-super-ok'
  },
  {
    label: 'Stoverload Music Podcast - Interview on YouTube',
    url: 'https://youtu.be/_lRWZFCHdtI'
  },
  */
  {
    label: 'Super OK Website',
    localUrl: '/'
  },
];

export default {
  name: 'HiComponent',
  async created() {
    // this.links = await this.fetchAndFormatLinks();
    this.links = backupList;
  },
  data: () => ({
    links: []
  }),
  components: {},
  methods: {
    async fetchAndFormatLinks() {
      try {
        const auth = await this.getAuth();
        console.log(auth);
        if (auth) {
          const linkTable = await this.fetchLinks(auth.temporaryAuthorization);
          if (linkTable) {
            const labelId = linkTable.fields.find(f => f.label === 'Label').id;
            const urlId = linkTable.fields.find(f => f.label === 'URL').id;
            return linkTable.data.map(d => {
              return {label: d[labelId].value, url: d[urlId].value};
            });
          } else {
            throw new Error('no linktable');
          }
        }
      } catch (e) {
        console.error(e);
        return backupList;
      }
    },
    getAuth() {
      const  headers = {
        'QB-Realm-Hostname': 'team.quickbase.com',
        'User-Agent': 'super_ok_hi',
        'QB-App-Token': 'cr56urb82c5n2bibjuc5ckbqj7s',
        'Content-Type': 'application/json'
      }


      return fetch('https://api.quickbase.com/v1/auth/temporary/bpit2pqaf',
          {
            method: 'GET',
            headers: headers,
            credentials: 'include'
          })
          .then(res => {
            if (res.ok) {
              return res.json();
            }
            return res.json().then(resBody => Promise.reject({status: res.status, ...resBody}));
            // for testing
            // return {temporaryAuthorization: "VEtU.OV9icjh3aHV2bmJfYjRpYnJ4X3V5cF9hX2JwaXQycHFhZl9jamc1bnhlZHFrenVzeGJ3cXl5cjRid2lncnJ1ZGNwOHhwNHUzYTZoN2R1MndqczN3OWFlOWtfZnlzZjdqcw=="}
          })
    },
    fetchLinks(auth) {
      const headers = {
        'QB-Realm-Hostname': 'team.quickbase.com',
        'User-Agent': 'super_ok_hi',
        'Authorization': `QB-TEMP-TOKEN ${auth}`,
        'Content-Type': 'application/json'
      }
      // this is the query itself, we're not really doing anything complex yet
      // so just grabs everything from the table
      const body = {from: "br8vvhyiv"}

      return fetch('https://api.quickbase.com/v1/records/query',
          {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(body)
          })
          .then(res => {
            if (res.ok) {
              return res.json();
            }
            return res.json().then(resBody => Promise.reject({status: res.status, ...resBody}));
          })
          .catch(err => console.error(err))
    },
  }
}

</script>

<style lang="scss">

.buttons-container {
  width: 100%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.button {
  width: 85%;
  background: white;
  border: 1px solid gray;
  text-align: center;
  border-radius: 5px;
  padding: 15px;
  margin-bottom: 20px;
  color: initial;
  text-decoration: unset;
  &:hover {
    -webkit-box-shadow: 1px 0 6px 4px rgb(150 150 150 / 60%);
    box-shadow: 1px 0 6px 4px rgb(150 150 150 / 60%);
  }
  a {
    &:link, &:visited, &:hover, &:active {
      text-decoration: none;
      color: #2c3e50;
    }
  }
}

</style>
